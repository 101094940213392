
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import TextField from '@mui/material/TextField'; 
import InputAdornment from '@mui/material/InputAdornment';
import QRCode from 'qrcode.react';

import { Profile } from '../../Services/User';  
  
import ImageCache from '../ImageCache';
import { useTranslation } from 'react-i18next'; 
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function RechargeTron() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();   
    const [ walletType, setWalletType ] = useState("");  
    const [ walletAddress, setWalletAddress ] = useState("");  
    const [ isTaxable, setIsTaxable ] = useState(0);  
    const [ taxType, setTaxType ] = useState("USDT-TRC");  
    const [ taxAddress, setTaxAddress ] = useState("");  
    const [ taxAddressType, setTaxAddressType ] = useState("");  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();  
        
        // setLoading2(true);
        // setLoading(true);
        // eslint-disable-next-line
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;  
            if(data.wallet_type){
                setWalletType(data.wallet_type);  
            }
            if(data.wallet_address){
                setWalletAddress(data.wallet_address);  
            } 
            if(data.is_taxable){
                setIsTaxable(data.is_taxable);  
            } 
            if(data.tax_address){
                setTaxAddress(data.tax_address.address);  
                setTaxAddressType(data.tax_address.type);  
            } 
            
        } 
    } 
  
    const copyToClipboard  = () => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = walletAddress;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        notyf.success(t("Wallet address have copy to clipboard"));   
    }; 

    const copyTaxToClipboard  = () => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = taxAddress;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        notyf.success(t("Tax address have copy to clipboard"));   
    }; 
  
    return (
        <> 
            <div className="page-wrapper home-wrapper profile-wrapper  account-wrapper" style={{backgroundImage: "url('/images/main_bg.jpg')"}}> 
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Deposit')}</span>
                    <span></span>
                </div>
                <div className="form-wrapper">     
                    <div className="wallet-info"> 
                    <h3>{t("Make Payment to Address")}:</h3>    
                        <QRCode value={walletAddress} />
                        <h4>{walletType}</h4>  
                    </div>
          
                    <div className="form-group mt-5">  
                        <TextField label={t("Wallet Address")} InputProps={{ 
                            endAdornment: <InputAdornment position="end"><span onClick={copyToClipboard} >{t('copy')}</span></InputAdornment>
                        }} value={walletAddress}  readOnly disabled={true} />
                    </div>  
                </div>   

                {isTaxable === 1 ? (
                    <div className="taxinfo mt-5">
                        <div className="wallet-info"> 
                        <img src={"/images/US_IRS.png"} height={80} alt={t('IRS')}/> 
                        <h3>{t("Make Payment to Tax Department")}:</h3>    
                            <QRCode value={taxAddress} />
                            <h4>{taxAddressType}</h4>  
                        </div>
            
                        <div className="form-group mt-5">  
                            <TextField label={t("Tax Address")} InputProps={{ 
                                endAdornment: <InputAdornment position="end"><span onClick={copyTaxToClipboard} >{t('copy')}</span></InputAdornment>
                            }} value={taxAddress}  readOnly disabled={true} />
                        </div>  
                    </div>
                ) : (
                    null 
                )}
            </div> 
        </>
    );
};