
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import Box from '@mui/material/Box';  
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress'; 

import { Profile } from '../../Services/User';  
  
import Typography from '@mui/material/Typography';  
import { useTranslation } from 'react-i18next';

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function MyProfile() {  

    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ tel, setTel ] = useState("");   
    const [ realname, setRealname ] = useState("");  
    const [ lang, setLang ] = useState("");  
    const [ username, setUsername ] = useState("");  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
        // eslint-disable-next-line
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setTel(data.tel);  
            setRealname(data.real_name); 
            setUsername(data.username); 
            setLang(data.lang); 
            setProgress(data.credit); 
            localStorage.setItem('lang', data.lang);  
            
        } 
    } 

    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleLogout = () => {
        setOpenConfirm(true);
    };
  
    const handleCloseReject = () => {
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false); 
        logout();
    };
    const logout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const handleBank = () => { 
        navigate('/bank');
    }; 
    const handlePassword = () => { 
        navigate('/password');
    }; 
    const handlePasswordWithdraw = () => { 
        navigate('/withdraw-password');
    }; 
    
    const handleLanguage = () => { 
        navigate('/language');
    }; 

    const handleRealname = () => { 
        navigate('/realname');
    }; 

    const handleInvite = () => { 
        navigate('/invite');
    }; 
    const handleWithdrawalHistory = () => { 
        navigate('/withdrawal-history');
    }; 
    const handleDepositHistory = () => { 
        navigate('/deposit-history');
    }; 

    const [progress, setProgress] = React.useState(0);

    function LinearProgressWithLabel(props) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '40px', mr: 1 }}>
                Credit
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
               <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      
      LinearProgressWithLabel.propTypes = { 
        value: PropTypes.number.isRequired,
      };
      
  return (
    <>

        <div className="page-wrapper home-wrapper profile-wrapper" style={{backgroundImage: "url('/images/main_bg.jpg')"}}>     
            <div className="inner-top-nav">
                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                <span></span>
                <span></span>
            </div>
            <div className="app_wrapper">
                <div className="page-title"> 
                    <h4>{t("Profile")}</h4>
                </div>
                <div className="profile_nav">
                    <ul>
                        <li>
                            <span className="nav-link" onClick={handleRealname}>
                                <span className="nav-title"> 
                                    <span>{t("Real Name")}</span>
                                </span>
                                <span className="nav-badge">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </span>
                            </span>
                        </li>
                        <li>
                            <span className="nav-link" onClick={handleBank}>
                                <span className="nav-title"> 
                                    <span>{t("Bind Wallet Address")}</span>
                                </span>
                                <span className="nav-badge">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </span>
                            </span>
                        </li>
                        <li>
                            <span className="nav-link" onClick={handlePassword}>
                                <span className="nav-title"> 
                                    <span>{t("Password management")}</span>
                                </span>
                                <span className="nav-badge">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </span>
                            </span>
                        </li>
                        <li>
                            <span className="nav-link" onClick={handlePasswordWithdraw}>
                                <span className="nav-title"> 
                                    <span>{t("Withdraw Password management")}</span>
                                </span>
                                <span className="nav-badge">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </span>
                            </span>
                        </li>
                        <li>
                            <span className="nav-link" onClick={handleInvite}>
                                <span className="nav-title"> 
                                    <span>{t("Invite")}</span>
                                </span>
                                <span className="nav-badge">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </span>
                            </span>
                        </li>
                        <li>
                            <span className="nav-link" onClick={handleWithdrawalHistory}>
                                <span className="nav-title"> 
                                    <span>{t("Withdrawal History")}</span>
                                </span>
                                <span className="nav-badge">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </span>
                            </span>
                        </li>
                        <li>
                            <span className="nav-link" onClick={handleDepositHistory}>
                                <span className="nav-title"> 
                                    <span>{t("Deposit History")}</span>
                                </span>
                                <span className="nav-badge">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
   
        </div> 
    </>
  );
};