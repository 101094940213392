
import React, {useEffect,useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom'   
import Button from '@mui/material/Button'; 
import TextField from '@mui/material/TextField';
 
import { GetDeposit, PostDeposit, Profile } from '../../Services/User';  
import InputAdornment from '@mui/material/InputAdornment';
  
import LoadingSpinner from '../LoadingSpinner';  
import { useTranslation } from 'react-i18next';

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function RechargeForm() {  
 
    const { t } = useTranslation();
    const { type } = useParams();
    const navigate = useNavigate();  
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        GetRechargeInfo();   
    }, []); 

    const GetRechargeInfo = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            //let pay_name = type; 
            let pay_name = "eth"; 
            GetDeposit({
                token,
                pay_name
            }, CallbackRechargeInfo);  
        }
    } 
    
    const CallbackRechargeInfo = (returnData) => {  
        if(returnData.code == 1)
        {  
            var data = returnData.data;
            setWallettype(data.wallet_type);   
            setWalletaddress(data.wallet_address);  
        }  
    } 
     
    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    
    const [wallettype, setWallettype] = useState('');   
    const [walletaddress, setWalletaddress] = useState('');   
    const [amount, setAmount] = useState('');   
    const [hashcode, setHashcode] = useState('');    
  
    const handleDeposit = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');  
        // let pay_name = type; 
        let pay_name = wallettype; 
        PostDeposit({ 
            token,
            amount,
            hashcode,
            pay_name
        }, CallbackDeposit);
    } 
 
    const CallbackDeposit = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data;
            notyf.success(t(returnData.message));  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 

    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value) 
    } 
      
    useEffect(() => {
        setIsDisabled(amount === '' || hashcode === ''); 
    }, [amount, hashcode]);

  
    const copyToClipboard  = () => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = walletaddress;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        notyf.success(t("Wallet address have copy to clipboard"));   
    }; 
    
    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper recharge-wrapper" style={{backgroundImage: "url('/images/main_bg.jpg')"}}>  
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Deposit')}</span>
                    <span></span>
                </div>
                <div className="form-wrapper mt-5">  
                    <form className="form" onSubmit={handleDeposit} >   
                        <div className="form-group">  
                            <TextField size="small" value={wallettype} label={t('Wallet Type')} variant="outlined"   readOnly disabled/>
                        </div>  
                            
                        <div className="form-group text-center p-3 qrcode">  
                            <img src={"./images/eth.jpg"} title="eth"/>
                        </div>
                        <div className="form-group">  
                            <TextField label={t('Wallet Address')} InputProps={{ 
                                endAdornment: <InputAdornment position="end"><span onClick={copyToClipboard} >{t('copy')}</span></InputAdornment>
                            }} value={walletaddress}  readOnly disabled={true} />
                        </div>  
                         
                        <div className="form-group">  
                            <TextField type="text" required size="small" label={t('Deposit Amount')} variant="outlined"  onChange={(e)=>inputChangeHandler(setAmount, e)}/>
                        </div>
                        <div className="form-group">  
                            <TextField multiline  rows={4} required size="small" label={t('Hashcode')} variant="outlined"  onChange={(e)=>inputChangeHandler(setHashcode, e)}/> 
                        </div>  
                         
                        <div className="form-action">   
                            <button type="submit" className="btn btn-primary" disabled={isDisabled}  style={{backgroundImage: "url('/images/bg_button.png')"}}>{t('Submit')}</button> 
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};