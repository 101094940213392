
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
  
import LoadingSpinner from '../LoadingSpinner';  
import { useTranslation } from 'react-i18next'; 
import { vipList } from '../../Services/User';  
import Footer from '../Layout/Footer';
 
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
 
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#e7c907',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#fff',
    },
  }));
  

export default function Vip() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
 
    const [loading, setLoading] = React.useState(false)   
    const [vipData, setVipData] = useState([]);
    const [vipInfo, setVipInfo] = useState("");
    const [vipCount, setVipCount] = useState("");
    const [vip, setVip] = useState(1);
    const [vipPal, setVipPal] = useState(0);
    const [vipName, setVipName] = useState("");
    const [comm, setComm] = useState(0);
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }     
        getVipLists();
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
   
    const getVipLists = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {   
            setVipData([]); 
            let token   = tokenInfo;  
            vipList({ 
                token
            }, CallbackVipList);  
        }
    } 
    
    const CallbackVipList = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;   
            setVipData(lists.data); 
            setVip(lists.info.vip); 
            setVipName(lists.info.vipName); 
            setComm(lists.info.comm); 
            let vipCount = lists.info.day_d_count+'/'+lists.info.today_max;
            let vipInfo = lists.info.min+'/'+lists.info.max;
            let vipPal;

            // Convert lists.info.min to float and perform the calculation
            const minValue = parseFloat(lists.info.min);

            if (!isNaN(minValue) && minValue !== 0 && lists.info.max !== 0) {
                vipPal = (minValue / lists.info.max) * 100;
            } else {
            // Handle the case where the conversion to float fails, minValue is 0, or lists.info.max is 0
            // You may set a default value or handle the situation based on your requirements
                vipPal = 0; // Set a default value or handle the situation accordingly
            }
            setVipInfo(vipInfo); 
            setVipCount(vipCount); 
            setVipPal(vipPal); 
        } 
    } 
    
    const handlePageShop = (cid) => { 
        navigate('/shop/'+cid);
    }
     
    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper home-wrapper vip-wrapper" style={{backgroundImage: "url('/images/main_bg.jpg')"}}>   
                <div className="app_wrapper">
                    <div className="vip_info">
                        <img className="vip_current" src={`/images/new_vip${vip}.png`} width="225" alt={t("VIP")} /> 
                        <h4 className="heading">
                            <img src={"images/icon_pre.png"} height={18} alt={t("icon")}/>
                            <span>{t(vipName)}</span>
                            <img src={"images/icon_post.png"} height={18} alt={t("icon")}/>
                        </h4>
                        <h5>{t("Current Level")}</h5>
                        <div className="vip_progress">
                            <BorderLinearProgress variant="determinate" value={vipPal} /> 
                            <div className="vip_progress_info">
                                <span>{vipInfo}</span>
                                <span>{vipCount}</span>
                            </div>
                        </div> 
             
                    </div>
                </div> 
                <div className="vip_row">
                    <div className="app_wrapper">
                        <div className="vip_curr"> 
                            <span className="vip_curr_info">
                                <img src={`/images/icon_dollar.png`} width={22} alt={t("Level")} />
                                <span>
                                    {t("Level")}: {t(vipName)}
                                </span>
                            </span>
                            <span className="vip_curr_info">
                                <img src={`/images/icon_diamond.png`} width={22} alt={t("Commission")} />
                                <span>
                                    {t("Commission")}: {comm}%
                                </span>
                            </span> 
                        </div>
                    </div>
                </div>
                <div className="app_wrapper">
                    
                    <div className="vip-lists">   
                        {vipData.length > 0 ? (
                            <>
                            {vipData.map((item, index) => ( 
                                <div className={`vip-item vip-item-${index}`} key={index}  onClick={() => handlePageShop(item.id)} > 
                            

                                    <div className="vip_heading"> 
                                        <img className="vip_icon" src={`/images/vip${index + 1}.png`} height="100"  alt={t("VIP")} /> 
                                        <h4>{t(item.name)}</h4>
                                    </div>
                             
                                    <div className="vip_curr"> 
                                        <span className="vip_curr_info">
                                            <img src={`/images/icon_dollar.png`} width={22} alt={t("Level")} />
                                            <span>
                                                {t("Level")}: {t(item.name)}
                                            </span>
                                        </span>
                                        <span className="vip_curr_info">
                                            <img src={`/images/icon_diamond.png`} width={22} alt={t("Commission")} />
                                            <span>
                                                {t("Commission")}: {item.bili * 100}%
                                            </span>
                                        </span> 
                                    </div> 
                                </div>  
                            ))}
                            </>
                        ) : (
                            <div className="text-center">{t('No record available')}</div>
                        )} 
                    </div>
                </div>
            </div> 
        <Footer/>
        </>
    );
};