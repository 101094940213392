import React from 'react';
import { useTranslation } from 'react-i18next'; 
 
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 

const SliderCarouselCenter = ({ imageData }) => {

    const { t } = useTranslation();
     
  
    const imageDataArray = Object.values(imageData);
        
    if (imageDataArray.length === 0) {
        return ( <></>);
    }
    
    const settings = { 
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 2,
      slidesToScroll: 4,
      speed: 500, 
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: { 
            slidesToShow: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: { 
            slidesToShow: 3, 
          }
        },
        {
          breakpoint: 480,
          settings: { 
            slidesToShow: 1,
          }
        }
      ]
    };

  return ( 
      <div className="module-slider slider-mode-center"> 
          <Slider {...settings}>
            
          {imageDataArray.map(item => (
            <div className="slider-item" key={item.id}>
              <div className="slider-item-info">
                <div className="slider-item-image">
                    <img src={item.image} alt={`${t(item.title)}`} width="100" /> 
                </div>
              </div>
            </div> 
          ))} 
          </Slider>
      </div> 
  );
}

export default SliderCarouselCenter;
