
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'  
import Button from '@mui/material/Button'; 
import TextField from '@mui/material/TextField'; 
import InputAdornment from '@mui/material/InputAdornment';

import { GetBank, PostWithdraw, Profile, ethConfig } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner'; 
import { useTranslation } from 'react-i18next'; 
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 8000}); 

export default function WithdrawalETH() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    const [ amount, setAmount] = useState(0);   
    const [ password, setPassword] = useState(false);   
    const [ wallettype, setWallettype] = useState('');     
    const [ walletaddress, setWalletaddress] = useState('');     
    const [ wallet_id, setWallet_id] = useState('');      
    const [ balance, setBalance ] = useState(0);   

    const [ canUsdt, setCanUsdt] = useState(false);   
    const [ canEth, setCanEth] = useState(true);   
    const [ canVisa, setCanVisa] = useState(false);   
    const [ canMaster, setCanMaster] = useState(false);   
    const [ canPaypal, setCanPaypal] = useState(false);   
    const [ ethRate, setEthRate] = useState(0);   
    const [ amountETH, setAmountETH] = useState(0);   
  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
        getEthConfig();   
  
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []); 

    const handleGoBack = () => {
        window.history.back();  
    };
     
    const getEthConfig = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            ethConfig({
                token
            }, CallbackEthConfig);  
        }
    } 

    const CallbackEthConfig = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setEthRate(parseFloat(data.rate));   
        } 
    } 

    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setBalance(parseFloat(data.balance));
            if (data.pwd2 === null || data.pwd2 === "") { 
                notyf.error(t("Please set withdrawal password to continue"));   
                navigate('/withdraw-password'); 
            }  else {
                UserBank();  
            }
                
        } 
    } 

    const UserBank = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo;  
            let type = 'eth'; 
            GetBank({
                token,
                type
            }, CallbackGetBank);  
        }
    } 
    
    const CallbackGetBank = (returnData) => {  
        if(returnData.code === 1)
        { 
            var data = returnData.data;  
            if(data === null){ 
                notyf.error(t("Please set wallet address(ETH) to continue"));     
                navigate('/bank/eth'); 
            } else {
                setWallettype(data.accountname.toUpperCase());
                setWalletaddress(data.accountnum);
                setWallet_id(data.id);
            }
        }  
    } 

    const handleWithdrawal = async e => { 
        e.preventDefault();      
        setIsDisabled(true);
        let amount2 = parseFloat(amount);
        let balance2 = parseFloat(balance);
        if(amount2 > balance2){ 
            notyf.error(t("Withdrawal amount cannot more than USDT ")+balance);
            setIsDisabled(false);
            return false;   
        }
        setLoading(true);  
        let token = localStorage.getItem('token');  
        let type = wallettype
        PostWithdraw({ 
            token,
            amount,
            password,
            type,
            wallet_id,
        }, CallbackWithdrawal);
    } 
 
    const CallbackWithdrawal = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        {  
            notyf.success(t(returnData.message));  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
            setIsDisabled(false);
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
    
    const withdrawAll = () => { 
        setAmount(parseFloat(balance))
    }

    useEffect(() => {
        setIsDisabled(amount <= 0 || password === ''); 
    }, [amount, password]);

    useEffect(() => { 
        if(parseFloat(ethRate) > 0){ 
            let convertedAmount = parseFloat(amount) / parseFloat(ethRate);
            setAmountETH(parseFloat(convertedAmount).toFixed(4)); 
        }
    }, [amount, ethRate]);

    const cardStyle = { 
        backgroundImage: 'url(/images/balance_bg.png)',
        backgroundPosition: 'top center',    
        backgroundSize: 'contain',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat'
    }; 
    
    const handleAmountChange = (event) => {
        // Parse the input value to a float and set it as the new amount
        setAmount(parseFloat(event.target.value));
    };
    
    const clickWithdrawal = (type) => { 
        navigate('/withdrawal'+type);
    };
    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 

            <div className="page-wrapper home-wrapper withdraw-wrapper" style={{backgroundImage: "url('/images/main_bg.jpg')"}}>      
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Withdrawal Payment')}<span className="textUpper"> (ETH)</span></span>
                    <span></span>
                </div>
                <div className="form-wrapper">  
                     <form className="form" onSubmit={handleWithdrawal} >   
                            
                        <div className="card app_wrapper" style={cardStyle}>  
                       
                            <div className="balance_info">
                                <h4>{t("Your Balance")}</h4>
                                <h2>${balance}</h2>
                                <span className="btn-withdrawall" onClick={withdrawAll} >{t('withdraw all')}</span>
                            </div> 
                            
                            <div className="balance-note">{t("WD Fee rate 5%")}</div>
                        </div>
                       
                        <div className="form-group">  
                            <label>{t("Enter Withdraw Amount")}</label>
                            <div className="input-withdraw">
                                <span className="label_currency usdt">
                                <span>
                                    <img src="/images/icon_usdt.svg"/>
                                    USDT
                                </span>
                                </span>
                                <span className="input_box">
                                    <span className="input_prefix">$</span>
                                    <input name="amount" type="number" 
                                    onChange={handleAmountChange}  
                                    value={amount} required min="1" max={balance}/>
                                </span>
                            </div>   
                        </div>
                        <div className="form-group">  
                            <label>{t("ETH Amount")}</label><small className="form-note">**1 ETH= {ethRate} USDT</small>
                            <div className="input-withdraw">
                                <span className="label_currency eth">
                                <span>
                                    <img src="/images/eth.png" width="20"/>
                                    ETH
                                </span>
                                </span>
                                <span className="input_box">
                                    <span className="input_prefix"></span>
                                    <input name="amount" type="text" value={amountETH} readonly/>
                                </span>
                            </div>   
                        </div>
 
                        <div className="form-group">  
                            <label>{t("Withdraw to")}</label>
                            <div className="input-currency"> 
                                <span onClick={() => clickWithdrawal("USDT")} className={`input-curr-type ${wallettype == 'TRC20' ? 'active' : ''} ${!canUsdt ? 'disabled' : ''}`}><img src="/images/icon_usdt.png"/> </span> 
                                <span onClick={() => clickWithdrawal("ETH")} className={`input-curr-type ${wallettype == 'ETH' ? 'active' : ''}  ${!canEth ? 'disabled' : ''}`}><img src="/images/icon_eth.png"/> </span> 
                                {/* <span className={`input-curr-type ${wallettype == 'VISA' ? 'active' : ''}  ${!canVisa ? 'disabled' : ''}`}><img src="/images/icon_visa.png"/> </span> 
                                <span className={`input-curr-type ${wallettype == 'MASTER' ? 'active' : ''}  ${!canMaster ? 'disabled' : ''}`}><img src="/images/icon_master.png"/> </span> 
                                <span className={`input-curr-type ${wallettype == 'PAYPAL' ? 'active' : ''}  ${!canPaypal ? 'disabled' : ''}`}><img src="/images/icon_paypal.png"/> </span>  */}
                            </div>   
                        </div>

                        <div className="form-group">  
                            <label>{t("Wallet Type")}</label>
                            <input className="form-control" name="wallet_type" type="text"  onChange={(e)=>inputChangeHandler(setAmount, e)} value={wallettype} readOnly disabled={true} />
                        </div>
  
                        <div className="form-group">  
                            <label>{t("Wallet Address")}</label>
                            <input className="form-control" name="wallet_type" type="text"  onChange={(e)=>inputChangeHandler(setAmount, e)} value={walletaddress} readOnly disabled={true} />
                        </div>
  
                        <div className="form-group">  
                            <label>{t("Withdrawal Password")}</label>
                            <input className="form-control password" name="password" type="password"  onChange={(e)=>inputChangeHandler(setPassword, e)} required/>
                        </div>  
                        <div className="form-action">   
                            <button type="submit" className="btn btn-primary" disabled={isDisabled}  style={{backgroundImage: "url('/images/bg_button.png')"}}>{t('Withdraw Now')}</button> 
                        </div>   
                    </form> 
                </div> 
            </div> 
        </>
    );
};