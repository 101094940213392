import React from 'react';
import ImageCache from '../ImageCache';
import { useTranslation } from 'react-i18next'; 

function OrderdetailComponent({ item }) {
 
    const { t } = useTranslation();
    if (!item || item.length === 0) {
        return <p>{t('No data available')}</p>;
    }
    
  return (
    <div>
      <div className="records_tabs"  key={item.orderNumber}>
     
        <span className={`order_status order_${item.status}`}>{t(item.statusTitle)}</span> 
      
        <div className="records_row">
            <span className="records_row_title">{t('Match time')}</span>
            <span className="records_row_value">{item.orderTime}</span> 
        </div>
        <div className="records_row">
            <span className="records_row_title">{t('Order Number')}</span>
            <span className="records_row_value">{item.orderNumber}</span>  
        </div>

        <div className="records_row_lists">
        {item.good_lists !== null && item.good_lists.map(good_list => ( 
            <div className="good_lists"  key={good_list.goods_id}>
                <div className="good_img"><ImageCache loading="lazy"  src={good_list.goods_pic} alt={good_list.goods_name} height={60}/></div>
                <div className="good_info">
                    <h4>{good_list.goods_name}</h4>
                    <h5>{good_list.goods_price} x {good_list.goods_count}</h5>
                </div>
            </div>
        ))}
        </div>
        
        <div className="records_row">
            <span className="records_row_title">{t('Total Order Amount')}</span>
            <span className="records_row_value">{item.totalAmount}</span>  
        </div>
        <div className="records_row">
            <span className="records_row_title">
                <img src="/images/icon_diamond.png" width={15}/>
                <b>{t('commission')}</b>
            </span>
            <span className="records_row_value"><b>{item.totalCommission}</b></span>  
        </div>
        <div className="records_row">
            <span className="records_row_title">{t('expected return')}</span>
            <span className="records_row_value">{item.totalExpected}</span>  
        </div>
        {item.gap > 0 ? ( 
        <div className="records_row"> 
            <div className="records_row_title">{t('Cash gap between task')}</div>
            <div className="records_row_value">{item.gap}</div>
        </div>
        ) : ('')}
    </div>
    </div>
  );
}

export default OrderdetailComponent;
