
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import TextField from '@mui/material/TextField'; 
import InputAdornment from '@mui/material/InputAdornment';
import QRCode from 'qrcode.react';

import { Profile } from '../../Services/User';  
  
import ImageCache from '../ImageCache';
import { useTranslation } from 'react-i18next'; 
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Invite() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();   
    const [ inviteCode, setInviteCode ] = useState("");  
    const [ inviteUrl, setInviteUrl ] = useState("");  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();  
        
        // setLoading2(true);
        // setLoading(true);
        // eslint-disable-next-line
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setInviteCode(data.invite_code); 
            let url = "https://shopbacktest.hellowrd.com/register/"+data.invite_code
                        
            const modifiedUrl = replaceDomain(url);
            setInviteUrl(modifiedUrl);  
        } 
    } 
  
    const copyToClipboard  = () => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = inviteUrl;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        notyf.success(t("Invite url have copy to clipboard"));   
    }; 

    function replaceDomain(apiurl) {
        const browserUrl = window.location.href;
 
        const apiDomainMatch = apiurl.match(/^(https?:\/\/)([^/]+)/);
        const apiDomain = apiDomainMatch ? apiDomainMatch[2] : null;
        
        // Parse the domain from the browser URL
        const browserDomainMatch = browserUrl.match(/^(https?:\/\/)([^/]+)/);
        const browserDomain = browserDomainMatch ? browserDomainMatch[2] : null;
        
        // Replace the domain in the API URL with the domain from the browser URL
        if (apiDomain && browserDomain) {
            return apiurl.replace(apiDomain, browserDomain);
        }
        
        // Return the original URL if domains couldn't be parsed
        return apiurl;
    }

  return (
    <> 

        <div className="page-wrapper home-wrapper profile-wrapper  invite-wrapper" style={{backgroundImage: "url('/images/main_bg.jpg')"}}>     
            <div className="inner-top-nav">
                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                <span>{t('Invite Friend')}</span>
                <span></span>
            </div> 
            <div className="module"> 
                <div className="invite-page">  
                    <div className="mb-5 mt-3">  
                        <ImageCache loading="lazy"  src={"/images/logo.png"} alt="Logo" height={80}/>
                    </div>
                    <QRCode value={inviteUrl} />
                    <h4>{inviteCode}</h4>  

                    <div className="form-group mt-5">  
                        <label>{t('Referral Url')}</label>
                        <div className="input-group mb-3">
                            <input value={inviteUrl} readOnly className="form-control" />
                            <div className="input-group-append">
                                <span class="input-group-text">
                                    <span onClick={copyToClipboard} >{t('copy')}</span>
                                </span>
                            </div>
                        </div>
                    </div> 
                </div>  
            </div>
  
        </div> 
    </>
  );
};