
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
  
import { teamReport } from '../../Services/User';   
import Footer from '../Layout/Footer';
import DownlineComponent from './DownlineComponent';  
import { useTranslation } from 'react-i18next'; 

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 


export default function TeamReport() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [list, setList] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); 
    const [lvl, setLvl] = useState(1); 

    
    const [currency, setCurrency] = useState("USDT");
    const [teamyue, setTeamyue] = useState(0);
    const [teamls, setTeamls] = useState(0);
    const [teamcz, setTeamcz] = useState(0);
    const [teamtx, setTeamtx] = useState(0);
    const [teamyj, setTeamyj] = useState(0);
    const [teamsc, setTeamsc] = useState(0);
    const [zhitui, setZhitui] = useState(0);
    const [tuandui, setTuandui] = useState(0);
    const [teamnew, setTeamnew] = useState(0);
    const [teamhy, setTeamhy] = useState(0);

   
    const fetchData = (lvl, page) => {
      // Set the active tab
      setLvl(lvl);
      setCurrentPage(page)
   
      getTeamReport(lvl, page);
    };
 
    useEffect(() => {  
        fetchData(lvl, currentPage)
        // eslint-disable-next-line
    }, [ currentPage]); 
 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    const getTeamReport = (lvl, page) => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {   
            let token   = tokenInfo;   
            let level   = lvl;   
            teamReport({ 
                token,
                level,
                page
            }, CallbackTeamReport);  
        }
    } 
    
    const CallbackTeamReport = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setTeamyue(data.teamyue)
            setTeamls(data.teamls)
            setTeamtx(data.teamtx)
            setTeamyj(data.teamyj)
            setTeamsc(data.teamsc)
            setZhitui(data.zhitui)
            setTuandui(data.tuandui)
            setTeamnew(data.teamnew)
            setTeamhy(data.teamhy) 
            setTeamcz(data.teamcz)

            var lists = data.lists;   
            setList([]);
            setList(lists.data);
            setCurrentPage(lists.current_page);
            setTotalPages(lists.last_page); 
        } 
    } 
      
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
          setCurrentPage(newPage);
        }
    };
    
    return (
    <>
        <div className="page-wrapper record-wrapper"> 
            <div className="inner-top-nav">
                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                <span></span>
                <span></span>
            </div>

            <div className="app_wrapper"> 
                <div className="page-title"> 
                    <h4>{t("Team report")}</h4>
                </div>

                <div className="report">
                    <div className="lf">
                        <p>{t('Team  balance')}</p>
                        {currency} {teamyue}
                    </div>
                    <div className="rg">
                        <p>{t('Team cash flow')}</p>
                        {currency} {teamls}
                    </div>
                    <div className="lf">
                        <p>{t('Team total top up')}</p>
                        {currency} {teamcz}
                    </div> 
                    <div className="rg">
                        <p>{t('Team total withdraw')}</p>
                        {currency}{teamtx}
                    </div> 
                    <div className="lf"><p>{t('Team order commission ')}</p>
                        {currency} {teamyj}
                    </div> 
                    <div className="rg"><p>{t('Headcount')}</p>
                        {teamsc}
                    </div> 
                    <div className="lf"><p>{t('Direct push')}</p>
                        {zhitui}
                    </div> 
                    <div className="rg"><p>{t('Team of people')}</p>
                        {tuandui}
                    </div> 
                    <div className="lf"><p>{t('New number of people')}</p>
                        {teamnew}
                    </div> 
                    <div className="rg"><p>{t('Activity people ')}</p>
                        {teamhy}
                    </div>
                </div>

                <div className="inner-tab-nav">
                    <span
                        className={`nav-tab ${lvl === 1 ? 'active' : ''}`}
                        onClick={() => fetchData(1, 1)}
                    >
                        {t('First level')}
                    </span>
                    <span
                        className={`nav-tab ${lvl === 2 ? 'active' : ''}`}
                        onClick={() => fetchData(2, 1)}
                    >
                        {t('Second level')}
                    </span>
                    <span
                        className={`nav-tab ${lvl === 3 ? 'active' : ''}`}
                        onClick={() => fetchData(3, 1)}
                    >
                        {t('Third level')}
                    </span>
                </div>

                <div className="record-lists"> 
                {list.length > 0 ? (
                    list.map(item => ( 
                        <DownlineComponent item={item} key={item.id}/>
                    )) 
                ) : (
                    <div className="records_tabs"> 
                        <div className="text-center">{t('No record available')}</div> 
                    </div> 
                )}
                </div> 
                         
                {list.length > 0 ? (           
                    <div className="paginator">
                            <span
                                className={`paginator-link ${currentPage === 1 ? 'disabled' : ''}`}
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <i className="fa-solid fa-chevron-left"></i>
                            </span>
                            <span>{t('Page')} {currentPage} / {totalPages}</span>
                            <span 
                                className={`paginator-link ${currentPage === totalPages ? 'disabled' : ''}`} 
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <i className="fa-solid fa-chevron-right"></i>
                            </span>
                        </div>
                    ): null}
    
            </div>
        </div>
        <Footer/>
    </>
    );
};